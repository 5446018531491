
.input-vin {
  font-size: 1.8rem;
  text-align: center;
  text-transform: uppercase;

  &::-webkit-input-placeholder {
    text-transform: none;
  }

  &:-moz-placeholder {
    text-transform: none;
  }

  &::-moz-placeholder {
    text-transform: none;
  }

  &:-ms-input-placeholder {
    text-transform: none;
  }

  &::placeholder {
    text-transform: none;
  }
}
