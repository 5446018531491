
@import "@/assets/scss/material-kit.scss";

.img-logo {
  max-height: 55px;
}

.navbar-nav .router-link-active {
  background-color: $gray-200;
  border: 1px solid $gray-400;
  border-radius: 0.5rem;
}

.dropdown-menu-end:before {
  color: #7b8090 !important;
}

.dropdown-menu:before {
  font-family: "Font Awesome 6 Pro" !important;
  content: "\f077" !important;
  font-size: 1rem !important;
  top: -15px !important;
}

.align-items-baseline {
  align-items: baseline !important;
}
